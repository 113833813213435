import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { logo, successLogo } from "./assets";
import  {getStorageData} from "../../../framework/src/Utilities";
import { AlertColor } from '@mui/material/Alert';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  otp: string;
  otpAuthToken: string;
  userAccountID: string;
  labelInfo: string;
  toMessage: string;
  isFromForgotPassword: boolean;
  resendTime: number;
  logo:string;
  successLogo:string;
  showToast: boolean;
  toastSeverity:AlertColor;
  showToastMessage: string;
  isEmailVerified: boolean;
  fromForgotPage: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class OTPInputAuthController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  timer: NodeJS.Timeout | null = null;
  otpAuthApiCallId: any;
  btnTxtSubmitOtp: string;
  placeHolderOtp: string;
  labelInfo: string = "";
  submitButtonColor: any = configJSON.submitButtonColor;
  submitOTPApiCallId: any;
  resendOTPApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      otp: "",
      otpAuthToken: "",
      userAccountID: "",
      labelInfo: configJSON.labelInfo,
      toMessage: "",
      isFromForgotPassword: false,
      resendTime: 120,
      logo:logo.default,
      successLogo:successLogo.default,
      showToast: false,
      showToastMessage: '',
      toastSeverity:'error',
      isEmailVerified:false,
      fromForgotPage:false
     
    };

    this.btnTxtSubmitOtp = configJSON.btnTxtSubmitOtp;
    this.placeHolderOtp = configJSON.placeHolderOtp;
    // Customizable Area End
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start

     if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.otpAuthApiCallId != null &&
      this.otpAuthApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (
        responseJson &&
        (responseJson.messages ||
          (responseJson.meta && responseJson.meta.token))
      ) {
        if (responseJson.meta && responseJson.meta.token) {
          this.setState({
            otpAuthToken: responseJson.meta.token,
          });
        }

        if (this.state.isFromForgotPassword) {
          // runEngine.debugLog("about to send NavigationNewPasswordMessage");
          const msg: Message = new Message(
            getName(MessageEnum.NavigationNewPasswordMessage)
          );
          msg.addData(
            getName(MessageEnum.AuthTokenDataMessage),
            this.state.otpAuthToken
          );

          msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

          this.send(msg);
        } else {
          const msg: Message = new Message(
            getName(MessageEnum.NavigationMobilePhoneAdditionalDetailsMessage)
          );

          msg.addData(
            getName(MessageEnum.AuthTokenDataMessage),
            this.state.otpAuthToken
          );

          msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

          this.send(msg);
        }
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (errorReponse != null) {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    
    this.receive1(message);
    this.receive2(message);
    this.receive3(message);
    this.receive4(message);
    // Customizable Area End
  }

  // Customizable Area Start
  receive1=(message:Message)=>{
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.resendOTPApiCallId != null &&
      this.resendOTPApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    
      if (responseJson && (responseJson.messages || responseJson.token)) {
        if (responseJson.token) {
          this.handleOTPResendRequestResponse(responseJson);
        }
      } else {
        this.showresendToast(responseJson);
      }
    }

  }

  receive2=(message:Message)=>{
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.submitOTPApiCallId != null &&
      this.submitOTPApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && (responseJson.messages)) {
           this.handleOTPRequestResponse()
      } else {
        this.showToast(responseJson)
      }

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (errorReponse != null) {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }

  }
  receive3=(message:Message)=>{
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const fromForgotPage =  message.getData(getName(MessageEnum.SessionResponseData));
      if(fromForgotPage.data === "Forgotpassword"){
        this.setState({
         fromForgotPage:true
        })
      }
    }

  }
  receive4=(message:Message)=>{
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const phoneAuthToken = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );

      const phoneNumber = message.getData(
        getName(MessageEnum.AuthTokenPhoneNumberMessage)
      );

      const forgotPasswordBool = message.getData(
        getName(MessageEnum.EnterOTPAsForgotPasswordMessage)
      );

      const emailValue = message.getData(
        getName(MessageEnum.AuthTokenEmailMessage)
      );

      const userAccountID = phoneNumber ? "" + phoneNumber : "" + emailValue;

      let updatedLabel = this.state.labelInfo;
      if (userAccountID && userAccountID !== "undefined") {
        updatedLabel = updatedLabel.replace("phone", userAccountID);
      }

      this.setState({
        otpAuthToken:
          phoneAuthToken && phoneAuthToken.length > 0
            ? phoneAuthToken
            : this.state.otpAuthToken,
        userAccountID: userAccountID,
        labelInfo: updatedLabel,
        isFromForgotPassword:
          forgotPasswordBool === undefined
            ? this.state.isFromForgotPassword
            : forgotPasswordBool,
      });
    }


  }
  async componentDidMount() {
    try {
      await new Promise(resolve => setTimeout(resolve, 100)); 
  
      const token = await getStorageData("token");
  
      if (token) {
        this.startResendTimer();
      } else {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountRegistration");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
      }
    } catch (error) {
     
    }
  }

  async componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
  handleChange = (otp: string) => {
    this.setState({ otp });
  };

  handleSubmit = async() => {
    if (this.state.otp.length === 6) {
      const header = {
        "Content-Type": configJSON.apiVerifyOtpContentType,
      };
      const data = {
        token: await getStorageData("token"),
        otp_code: this.state.otp ? this.state.otp : "",
      };

      const httpBody = {
        data: data,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.submitOTPApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.submitOTPApiEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeSubmitOTP
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;

    } else if(this.state.otp.length < 6) {
      this.setState({ showToastMessage: 'Please fill all OTP box', toastSeverity:'error' });
    }
  };

  startResendTimer = () => {
    this.setState({ resendTime: 300 });
    if (this.timer) clearInterval(this.timer);
    this.timer = setInterval(() => {
      this.setState(prevState => {
        if (prevState.resendTime <= 1) {
          if (this.timer) clearInterval(this.timer);
          return { resendTime: 0 };
        }
        return { resendTime: prevState.resendTime - 1 };
      });
    }, 1000);
  };

  handleCloseToast = () => {
    this.setState({ showToast: false });
  };

  showToast=(responseJson:any)=>{
    if(responseJson.errors[0].pin){
    this.setState({ showToastMessage: responseJson.errors[0].pin, showToast:true, toastSeverity:'error' });
    }else if(responseJson.errors[0].token){
      
      setInterval(() => {
        this.setState({ showToastMessage: responseJson.errors[0].token, showToast:true, toastSeverity:'error' });
      },2000)
      this.props.navigation("/EmailAccountRegistration")
    }
    else if(responseJson.errors[0].otp){
        this.setState({ showToastMessage: responseJson.errors[0].otp, showToast:true, toastSeverity:'error' });
      
    }
  }
  navigateToLandingPage=()=>{
    
      const message: Message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
  }
  navigateToSigninPage=()=>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);

  }
  handleOTPRequestResponse=()=>{
    
    if(this.state.fromForgotPage){
      const message: Message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "NewPassword");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    }else{
      this.setState({isEmailVerified:true})
    }
  }
  handleOTPResendRequestResponse=(responseJson:any)=>{
    this.setState({showToastMessage:responseJson.message, showToast:true, toastSeverity:'success'})
  }

  showresendToast=(responseJson:any)=>{
    this.setState({showToastMessage:responseJson.error, showToast:true, toastSeverity:'error'})
  }
  handleResetOTP=async()=>{
    this.startResendTimer();
    
    const header = {
      "Content-Type": configJSON.apiVerifyOtpContentType,
      token: await getStorageData("token"),
    };
    

  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.resendOTPApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resendOTPApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeResendOTP
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }


  async submitOtp() {
    if (!this.state.otp || this.state.otp.length === 0) {
      this.showAlert(configJSON.errorTitle, configJSON.errorOtpNotValid);
      return;
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (this.state.isFromForgotPassword) {
      // console.log("entered is from forgot password!");
      const header = {
        "Content-Type": configJSON.apiVerifyOtpContentType,
      };

      //GO TO REQUEST STATE
      this.otpAuthApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiVerifyForgotPasswordOtpEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      const data = {
        token: this.state.otpAuthToken ? this.state.otpAuthToken : "",
        otp_code: this.state.otp ? this.state.otp : "",
      };

      const httpBody = {
        data: data,
      };

      //requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
    } else {
      const headers = {
        "Content-Type": configJSON.apiVerifyOtpContentType,
        token: this.state.otpAuthToken,
      };

      this.otpAuthApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiVerifyOtpEndPoint + this.state.otp
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(JSON.stringify({}))
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiVerifyOtpMethod
    );
    // console.log("requestMessage.id is: " + requestMessage.id);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  btnSubmitOTPProps = {
    onPress: () => this.submitOtp(),
  };

  txtMobilePhoneOTPWebProps = {
    onChangeText: (text: string) => this.setState({ otp: text }),
  };

  txtMobilePhoneOTPMobileProps = {
    ...this.txtMobilePhoneOTPWebProps,
    keyboardType: "numeric",
  };

  txtMobilePhoneOTPProps = this.isPlatformWeb()
    ? this.txtMobilePhoneOTPWebProps
    : this.txtMobilePhoneOTPMobileProps;

  // Customizable Area End
}
