import React from "react";
// Customizable Area Start
import {
  TextField,
  FormControl,
  Button,
  Grid,
  Typography,
  ThemeProvider,
  FormLabel,
  FormHelperText,
  Snackbar,
  Alert,
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions,
  Divider,
  Box
} from '@mui/material';
import { createTheme } from '@mui/material/styles';

import * as Yup from "yup";
import { Formik, Form, Field } from 'formik';


const theme = createTheme({
    palette: {
      primary: {
        main: "#0000ff",
        contrastText: "#fff",
      },
    },
    typography: {
      fontFamily: "Arial",
    },
  });
  const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

function renderFormHelperText(error:any, touched:any, stateError:any) {
    const errorMessage = (error && touched && error) || stateError;
  
    if (errorMessage) {
        return (
            <FormHelperText
                style={{
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    textAlign: 'left',
                    color: "#DC2626",
                    marginLeft: "0px"
                }}
            >
                {errorMessage}
            </FormHelperText>
        );
    }
    return null;
  }
  
   
// Customizable Area End

const configJSON = require("./config.js");

import ForgotPasswordController, {
  Props
} from "./ForgotPasswordController";

class ForgotPassword extends ForgotPasswordController {
  static ForgotPassword: ForgotPassword;

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
 
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
        <ThemeProvider theme={theme}>
            <Grid
                container
                spacing={2}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{ minHeight: '100vh', px: 2, py: 4 }}
              >
                <Snackbar
                  open={this.state.showToast}
                  autoHideDuration={6000}
                  onClose={this.handleCloseToast}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  sx={{ top: { xs: "56px", sm: "64px" }, width: "100%" }}
                >
                  <Alert
                    onClose={this.handleCloseToast}
                    sx={{ width: "100%" }}
                    severity={this.state.isError ? "error" : "success"} 
                  >
                    {this.state.toastMessage}
                  </Alert>
                </Snackbar>
        
                <Grid
                  item
                  xs={12}
                  sm={8}
                  md={6}
                  lg={4}
                  sx={{ padding: { xs: '16px', sm: '24px', md: '32px' }, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                >
                  
                  <img 
                    src={this.state.logo}
                    alt="Logo" 
                    style={{ marginBottom: '40px', width: '150px',marginRight:'20px' }} 
                  />
         
        <Typography
                    variant="h5"
                    gutterBottom
                    sx={{
                      fontWeight: 'bold',
                      textAlign: 'center',
                      color: '#000000',
                      mb: 2,
                      fontSize: { xs: '20px', sm: '24px', md: '28px' },
                    }}
                  >
                    Reset Password
                  </Typography>
        
                  <Typography
                    variant="body1"
                    sx={{
                      textAlign: 'center',
                      mb: 3,
                      fontSize: { xs: '14px', sm: '16px', md: '18px' },
                    }}
                  >
                    To reset your password, please provide us with the email address you've signed up with.
                  </Typography>
        
                  <Formik
                  data-test-id="formik"
                    initialValues={{ email: "" }}
                    validationSchema={validationSchema}
                    onSubmit={(values):any => {
                        this.handleForgotPasswordSubmit(values)
                       }}>
                    {({ handleChange, handleBlur, values, errors, touched, isValid, dirty }) => (
                      <Form>
                      <FormControl fullWidth margin="normal">
                        <FormLabel
                          htmlFor="email"
                          sx={{
                            color: 'var(--Neutrals-Cool-gray-700, #334155)',
                            fontSize: '18px',
                            fontWeight: 700,
                            mb: 1,
                          }}
                        >
                          Email Address
                        </FormLabel>
                          <Field
                            as={TextField}
                            id="email"
                            data-test-id="email"
                            name="email"
                            placeholder="Enter your email address"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(errors.email) && touched.email}
                            InputLabelProps={{ shrink: true }}
                            sx={{
                              width: '100%',
                              maxWidth: '400px',
                              '& .MuiInputBase-root': {
                                height: '56px',
                              },
                            }}
                          />
                          {renderFormHelperText(errors.email, touched.email,this.state.errorMesssgae)}
                        </FormControl>
        
                        <Button
                          variant="contained"
                          fullWidth
                          type="submit"
                          data-test-id="handleforgotpassword"
                          sx={{
                            textTransform:'none',
                            height: '56px',
                            mt: 2,
                            backgroundColor: (isValid && dirty) ? 'var(--Primary-Purple-600, #9E52F6)' : '#F1F5F9',
                            "&:hover": {
                              backgroundColor: (isValid && dirty) ? 'var(--Primary-Purple-700, #8E41D1)' : '#64748B',
                            },
                          }}
                          disabled={!isValid || !dirty}
                        >
                          Continue
                        </Button>
                      </Form>
                    )}
                  </Formik>
        
                  <Typography
                    variant="body2"
                    sx={{
                      mt: 2,
                      textAlign: 'center',
                      fontSize: { xs: '14px', sm: '16px', md: '18px' },
                    }}
                  >
                    Not a member?{' '}
                    <span data-test-id="navigateToSignup" onClick={this.navigateToSignup} className="loginButton" style={{ textDecoration: 'none', color: '#9E52F6' }}>
                          Sign Up</span>
                  </Typography>
                </Grid>
                <Dialog
        open={this.state.open}
        onClose={this.handleClose}
        maxWidth="xs"
        fullWidth
        aria-labelledby="signup-dialog-title"
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: 3,
            padding: 2,
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          },
        }}
      >
        <DialogTitle id="signup-dialog-title" sx={{ textAlign: 'center' ,  color:"#334155",
          fontWeight:700,
          fontSize:'20px',
          fontFamily:'Inter'}}>
          Signup with
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ py: 2 }}>
          <Typography variant="body1" align="center" sx={{
          color:"#334155",
          fontWeight:400,
          fontSize:'15px',
          fontFamily:'Inter',

          }} gutterBottom>
            Select an option below:
          </Typography>
          <Box display="flex" justifyContent="center" gap={2} mt={2}>
            <Button
              data-test-id="navigateToUserSignup"
              variant={this.state.selectedOption === 'User' ? 'contained' : 'outlined'}
              onClick={() => this.navigateToUserSignup()}
              fullWidth
              sx={{
                fontWeight:700,
                fontSize:'15px',
                fontFamily:'Inter',
                borderRadius: '8px',
                border:'1px solid #CBD5E1',
                paddingY: 1,
                backgroundColor: '#F1F5F9',
                color: '#64748B',
                transition: '0.3s',
                boxShadow:'none',
                paddingBottom: '4px',
                textTransform:'none',
                '&:hover': {
                  border:'1px solid #CBD5E1',
                  backgroundColor: '#9E52F6',
                  color: '#FFFFFF',
                  boxShadow:'none',
                  paddingBottom: '4px'
                },
              }}
            >
              User
            </Button>
            <Button
              data-test-id="navigateToBrandSignup"
              variant={this.state.selectedOption === 'Brand' ? 'contained' : 'outlined'}
              onClick={() => this.navigateToBrandSignup()}
              fullWidth
              sx={{
                fontWeight:700,
                fontSize:'15px',
                fontFamily:'Inter',
                borderRadius: '8px',
                border:'1px solid #CBD5E1',
                paddingY: 1,
                backgroundColor: '#F1F5F9',
                color: '#64748B',
                transition: '0.3s',
                boxShadow:'none',
                paddingBottom: '8px',
                textTransform:'none',
                '&:hover': {
                  border:'1px solid #CBD5E1',
                  backgroundColor: '#9E52F6',
                  color: '#FFFFFF',
                  boxShadow:'none',
                  paddingBottom: '8px'
                },
              }}
            >
              Brand
            </Button>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ justifyContent: 'center', mt: 1 }}>
          <Button
            data-test-id="handleClose"
            onClick={this.handleClose}
            color="secondary"
            variant="outlined"
            sx={{
              fontWeight:700,
              fontSize:'15px',
              fontFamily:'Inter',
              borderRadius: '8px',
              border:'1px solid #CBD5E1',
              paddingY: 1,
              backgroundColor: 'transparent',
              color: '#9E52F6',
              transition: '0.3s',
              boxShadow:'none',
              paddingBottom: '8px',
              textTransform:'none',
              '&:hover': {
                border:'1px solid #CBD5E1',
                backgroundColor: '#F1F5F9',
                color: '#9E52F6',
                boxShadow:'none',
                paddingBottom: '8px'
              },
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
                
              </Grid>
            </ThemeProvider>

);
// Customizable Area End
  }
}

// Customizable Area Start

// Customizable Area End

export default ForgotPassword;



