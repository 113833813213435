import React from "react"; 
// Customizable Area Start 
import { TextField, FormControl, Button, Grid, Box, Typography, ThemeProvider, IconButton, FormLabel,FormHelperText, Snackbar, Alert } from '@mui/material'; 
import { createTheme } from '@mui/material/styles';

import * as Yup from "yup"; import { Formik, Form, Field } from 'formik'; 
import Visibility from '@mui/icons-material/Visibility'; 
import VisibilityOff from '@mui/icons-material/VisibilityOff'; 
import { CheckIcon, CrossIcon } from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Arial",
  },
});
const showPassword =(value:any)=>{
  return value ? "text" : "password"
  }
const showEyeButton =(value:any)=>{
    return value ? <Visibility /> : <VisibilityOff />
  }

const validationSchema = Yup.object({
    newPassword: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], "Passwords must match")
      .required("Confirm Password is required"),
  });
  
   
const hasUppercase = (password: string) => /[A-Z]/.test(password);
const hasLowercase = (password: string) => /[a-z]/.test(password);
const hasNumber = (password: string) => /\d/.test(password);
const hasMinLength = (password: string) => password.length >= 8;
  
  
  const renderFormHelperText = (error?: string, touched?: boolean) => {
    if (error && touched) {
      return (
        <FormHelperText
          style={{
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "20px",
            textAlign: "left",
            color: "#DC2626",
            marginLeft: "0px",
            
          }}
        >
          {error}
        </FormHelperText>
      );
    }
    return null;
  };
// Customizable Area End

const configJSON = require("./config.js");

import ForgotPasswordController, { Props } from "./ForgotPasswordController";

class NewPassword extends ForgotPasswordController { 
  static NewPassword: NewPassword;

constructor(props: Props) { 
  super(props); 
  // Customizable Area Start 
  // Customizable Area End 
}

// Customizable Area Start 
getToastMessage=()=> {
  const { showToastMessage } = this.state;
  if (typeof showToastMessage === 'string') {
    return showToastMessage;
  } else {
    return "Invalid message";
  }
}
// Customizable Area End

render() { 
// Customizable Area Start 
  return (
    <ThemeProvider theme={theme}>
           <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ 
              padding: '16px',
              boxSizing: 'border-box',
            }}
          >
           <Snackbar
  open={this.state.showToast}
  autoHideDuration={6000}
  onClose={this.handleCloseToast}
  anchorOrigin={{ vertical: 'top', 
  horizontal: 'center' }}
  sx={{ top: '0px', justifyContent: 'center' }}
>
<Alert
data-test-id="alertdatatest"
  onClose={this.handleCloseToast} 
  sx={{ width: '100%' }}
  severity="error"
>
  
  {this.getToastMessage()}
</Alert>

</Snackbar>
{!this.state.SuccessMessage && (
            <Grid
              item
              xs={12}
              sm={8}
              md={6}
              lg={4}
              sx={{ 
                padding: "16px", 
                display: "flex", 
                flexDirection: "column", 
                alignItems: "center", 
                background: theme.palette.common.white, 
                borderRadius: "8px", 
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" 
              }}
            >
              
              <Box>
              <img 
                src={this.state.logo}
                alt="Logo" 
                style={{ 
                  marginBottom: '20px', 
                  width: '150px', 
                 
                }} 
              /></Box>
    
              <Typography
                variant="h5"
                gutterBottom
                sx={{ 
                  fontWeight: 'bold', 
                  fontSize: '24px', 
                  textAlign: 'center' 
                }}
              >
                New Password
              </Typography>
              <Typography 
                variant="body1" 
                sx={{ 
                  mb: 2, 
                  textAlign: 'center', 
                  fontSize: '14px' 
                }}
              >
                Enter a new password for someone@gmail.com
              </Typography>
              <Formik
                data-test-id = "formik"
                initialValues={{ newPassword: "", confirmPassword: "" }}
                validationSchema={validationSchema}
                onSubmit={(values):any => {
                 this.handleSubmit(values)
                }}
              >
                {({ handleChange, handleBlur, values, errors, touched, isValid, dirty }) => (
                  <Form>
                    <FormControl fullWidth margin="normal">
                      <FormLabel htmlFor="newPassword">New Password</FormLabel>
                      <Field
                        as={TextField}
                        id="newPassword"
                        data-test-id="newPassword"
                        name="newPassword"
                        type={showPassword(this.state.showPassword)}
                        placeholder="Enter your new password"
                        value={values.newPassword}
                        onChange={(e:any) => {
                          handleChange(e);
                          this.handlePasswordChange(e);
                        }}
                        onBlur={handleBlur}
                        error={Boolean(errors.newPassword) && touched.newPassword}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          style: {
                            width: "100%",
                            height: "48px",
                            padding: "12px",
                            borderRadius: "8px",
                            background: theme.palette.common.white,
                            fontSize: "16px",
                            fontWeight: 400,
                            fontFamily: "Arial",
                          },
                          endAdornment: (
                            <IconButton
            data-test-id="toggleNewPassword"
            aria-label="toggle password visibility"
            onClick={this.handleClickShowPassword}
            onMouseDown={this.handleMouseDownPassword}
          >
            
            {showEyeButton(this.state.showPassword)}
          </IconButton>
                          ),
                        }}
                      />
                      {renderFormHelperText(errors.newPassword, touched.newPassword)}
                    </FormControl>
                    
                    <FormControl fullWidth margin="normal">
                      <FormLabel htmlFor="confirmPassword">Confirm Password</FormLabel>
                      <Field
                        as={TextField}
                        id="confirmPassword"
                        data-test-id="confirmPassword"
                        name="confirmPassword"
                        type={showPassword(this.state.showConfirmPassword)}
                        placeholder="Confirm your new password"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(errors.confirmPassword) && touched.confirmPassword}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          style: {
                            width: "100%",
                            height: "48px",
                            padding: "12px",
                            borderRadius: "8px",
                            background: theme.palette.common.white,
                            fontSize: "16px",
                            fontWeight: 400,
                            fontFamily: "Arial",
                          },
                          endAdornment: (
                            <IconButton
            data-test-id="toggleConfirmPassword"
            aria-label="toggle confirm password visibility"
            onClick={this.handleClickShowConfirmPassword}
            onMouseDown={this.handleMouseDownConfirmPassword}
          >
            {showEyeButton(this.state.showConfirmPassword)}
          </IconButton>
                          ),
                        }}
                      />
                      {renderFormHelperText(errors.confirmPassword, touched.confirmPassword)}
                    </FormControl>
    
                    {this.state.showValidationMessage && (
        <div>
          <p className="pass_check_title">Your Password must contain:</p>
          <ul style={{ display: "contents" }}>
            <li className="pass_check" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              {hasUppercase(this.state.password) ? CheckIcon : CrossIcon} At least one capital letter
            </li>
            <li className="pass_check" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              {hasLowercase(this.state.password) ? CheckIcon : CrossIcon} At least one lowercase letter
            </li>
            <li className="pass_check" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              {hasNumber(this.state.password) ? CheckIcon : CrossIcon} At least one number
            </li>
            <li className="pass_check" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              {hasMinLength(this.state.password) ? CheckIcon : CrossIcon} Minimum character length is 8 characters
            </li>
          </ul>
        </div>
      )}
                    <Button
                      variant="contained"
                      data-test-id='handleSubmit'
                      fullWidth
                      type="submit"
                      sx={{
                        mt: 2,
                        backgroundColor: isValid && dirty ? theme.palette.primary.main : '#e0e0e0',
                        color: isValid && dirty ? theme.palette.primary.contrastText : '#000',
                        "&:hover": {
                          backgroundColor: isValid && dirty ? theme.palette.primary.dark : '#e0e0e0',
                        },
                        textTransform: "none",
                        height: "48px",
                        borderRadius: "8px",
                        fontSize: "16px",
                        fontWeight: 400,
                      }}
                      disabled={!isValid || !dirty}
                    >
                      Set New Password
                    </Button>
    
                    <Grid container justifyContent="center" sx={{ mt: 2 }}>
                      {/* <Typography variant="body2" sx={{ textAlign: 'center' }}>
                        Back to{' '}
  
                        <span onClick={this.navigateToLogin} data-test-id="navigateToLogin" className="loginButton" style={{ textDecoration: 'none', color: '#9E52F6' }}>
                          login</span>
                      </Typography> */}
                      <Typography
                    variant="body2"
                    sx={{
                      mt: 3,
                      textAlign: 'center',
                      fontFamily: 'Inter',
                      fontSize: '20px',
                      fontWeight: 400,
                      lineHeight: '28px',
                      color:'#1E293B'
                    }}
                  >
                    Back to{' '}
                    <span onClick={()=>{this.navigateToSigninPage()}} data-test-id="navigateToLogin" className="loginButton" style={{ textDecoration: 'none', color: '#9E52F6',  textAlign: 'center',
                      fontFamily: 'Inter',
                      fontSize: '20px',
                      fontWeight: 700,
                      lineHeight: '28px', }}>
                          Login</span>
                  </Typography>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
)}
            {this.state.SuccessMessage && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: '#f9f9f9',
              padding: 2,
              textAlign: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                mb: 4,
              }}
            >
              
                <Box><img src={this.state.successLogo}></img></Box>
              
              <Typography variant="h5" sx={{  
                color:"#000000",
                fontFamily: 'Inter',
                fontSize: '30px',
                fontWeight: 700,
                lineHeight: '40px',
                letterSpacing: '-0.005em',
                textAlign: 'left', 
                marginTop:'36px'}}>
                Password Reset
              </Typography>
              <Box sx={{
                display: 'flex',
                flexWrap: 'wrap',
                paddingRight: '83px',
                paddingLeft: '83px'
              
              }}>
              <Typography variant="body1" sx={{ 
                fontFamily: 'Inter',
                fontSize: '20px',
                fontWeight: 400,
                lineHeight: '28px',
                textAlign: 'center',
                color :'#1E293B',
                marginTop:'24px',
                [theme.breakpoints.down('xs')]:{
                  fontSize: '16px',

                }
              }}>
                Your account is successfully recoverd,<br/> you can now browse the app with your new account.
              </Typography>
              </Box>
            </Box>
            <Button
              onClick={this.navigateToSigninPage}
              variant="contained"
              sx={{
                backgroundColor: '#A855F7',
                color: '#fff',
                width:'80%',
                height: '56px',
                borderRadius: '8px',
                '&:hover': {
                  backgroundColor: '#9333EA',
                },

                fontFamily: 'Inter',
                fontSize: '20px',
                fontWeight: 700,
                lineHeight: '28px',
                textAlign: 'left',
                textTransform:'none'
              }}
            >
              Continue
            </Button>
          </Box>
        )}

          </Grid>
        </ThemeProvider>

); 
// Customizable Area End

}
 }

// Customizable Area Start

// Customizable Area End

export default NewPassword;