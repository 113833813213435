import React from "react";
// Customizable Area Start
import { Box, Button, Typography, Snackbar, Alert, ThemeProvider, styled } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import OtpInput from 'react-otp-input';
const theme = createTheme({
    palette: {
      primary: {
        main: "#0000ff",
        contrastText: "#fff",
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  });
// Customizable Area End

const configJSON = require("./config.js");

import OTPInputAuthController, {
  Props
} from "./OTPInputAuthController";

class OTPInputAuth extends OTPInputAuthController {
  static OTPInputAuth: OTPInputAuth;

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderTitleMessage = () => {
    return this.state.fromForgotPage ? "Check Your Email" : "Verify Your Account";
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { otp, resendTime } = this.state;
    const isResendButtonDisabled = resendTime > 0;
    const isNextButtonEnabled = otp.length === 6;
    
    const minutes = Math.floor(resendTime / 60);
    const seconds = resendTime % 60;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    const timeString = `in ${minutes}:${formattedSeconds} minutes`;

    return (
        <>
         <MainWrapper>
        <ThemeProvider theme={theme}>
        <Snackbar
  open={this.state.showToast}
  autoHideDuration={6000}
  onClose={this.handleCloseToast}
  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
  sx={{ top: '0px', justifyContent: 'center' }}
>
<Alert
  onClose={this.handleCloseToast} 
  severity={this.state.toastSeverity || "error"} 
  sx={{ width: '100%' }}
>
  {typeof this.state.showToastMessage === 'string' ? this.state.showToastMessage : "Invalid message"}
</Alert>

</Snackbar>
       
        {!this.state.isEmailVerified && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh',
              bgcolor: '#f9f9f9',
              padding: 2,
              textAlign: 'center',
            }}
          >
            <Box
              component="img"
              src={this.state.logo} 
              alt="Logo"
              sx={{ width: 320, height: 'auto', marginBottom: '75px' }}
            />

            <Typography variant="h5" component="h1" sx={{
              marginBottom: '24px',
              color: "#000000",
              fontFamily: 'Inter',
              fontSize: '30px',
              fontWeight: 700,
              lineHeight: '40px',
              letterSpacing: '-0.005em',
              textAlign: 'center',
            }}>
             {this.renderTitleMessage()}
            </Typography>
            <Typography variant="body1" sx={{
              color: "#1E293B",
              marginBottom: '24px',
              fontFamily: 'Inter',
              fontSize: '20px',
              fontWeight: 400,
              lineHeight: '28px',
              textAlign: 'center',
            }}>
             {!this.state.fromForgotPage && (
  <>
    We’ve sent an email containing an OTP code to verify your email. <br />
    Please check your email.
  </>
)}


             {this.state.fromForgotPage&& "We've sent an OTP to the email you've provided to create a new password"} 
            </Typography>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: '8px', 
                flexWrap: 'wrap',
                maxWidth: '100%',
                width: 'auto',
                marginBottom: '24px',
                paddingLeft: '45px',
                [theme.breakpoints.down(900)]:{
                  paddingLeft: '0px',

                }
              }}
            >
              <OtpInput
                data-test-id="inputOtp"
                value={otp}
                inputType="tel"
                onChange={this.handleChange}
                containerStyle={{ outline: "none", display: 'flex', justifyContent: 'center' }}
                numInputs={6}
                renderInput={(props) => <input {...props} className="otp-input" />}
                inputStyle={{
                
                }}
              />
            </Box>

            <Typography
              sx={{
                color: "#94A3B8",
                fontFamily: 'Inter',
                fontSize: '20px',
                fontWeight: 700,
                lineHeight: '30px',
                letterSpacing: '-0.005em',
                textAlign: 'center',
                marginTop: '24px',
                whiteSpace: 'pre-line', 
              }}
            >
              {resendTime > 0 ? (
                <>
                  <Box sx={{marginBottom:'-20px'}}>Resend</Box>
                  <br/>
                  <span
                    style={{
                        color :'#94A3B8',
                        fontFamily: 'Inter',
                        fontSize: '18px',
                        fontWeight: 400,
                        lineHeight: '24px',
                        letterSpacing: '-0.005em',
                        textAlign: 'left',
                    }}
                  >
                  {timeString}
                  </span>
                </>
              ) : (
                <Box
                  data-test-id="handleResetOTP"
                  onClick={this.handleResetOTP}
                  color={isResendButtonDisabled ? '#94A3B8' : '#9E52F6'}
                >
                  Resend now
                </Box>
              )}
            </Typography>

            <Typography variant="body2" sx={{
              marginTop: '8px',
              color: "#94A3B8",
              fontFamily: 'Inter',
              fontSize: '20px',
              fontWeight: 400,
              lineHeight: '30px',
              letterSpacing: '-0.005em',
              textAlign: 'center',
            }}>
              
            </Typography>

            <Button
              data-test-id="handleSubmit"
              onClick={this.handleSubmit}
              variant="contained"
              sx={{
                color: isNextButtonEnabled ? '#FFFFFF' : '#64748B',
                fontFamily: 'Inter',
                fontSize: '18px',
                fontWeight: 700,
                lineHeight: '26px',
                textAlign: 'left',
                textTransform: 'none',
                marginTop: '35px',
                backgroundColor: isNextButtonEnabled ? '#9E52F6' : '#F1F5F9',
                width: '100%',
                maxWidth: '780px', 
                height: '56px',
                borderRadius: '8px',
                '&:hover': {
                  backgroundColor: isNextButtonEnabled ? '#A96BE7' : '#d0d0d0',
                },
              }}
              disabled={!isNextButtonEnabled}
            >
              Next
            </Button>
            {this.state.fromForgotPage&&<Typography
                    variant="body2"
                    sx={{
                      mt: 3,
                      textAlign: 'center',
                      fontFamily: 'Inter',
                      fontSize: '20px',
                      fontWeight: 400,
                      lineHeight: '28px',
                      color:'#1E293B'
                    }}
                  >
                    Back to{' '}
                    <span data-test-id="navigateToSigninPage" onClick={this.navigateToSigninPage} className="loginButton" style={{ textDecoration: 'none', color: '#9E52F6',  textAlign: 'center',
                      fontFamily: 'Inter',
                      fontSize: '20px',
                      fontWeight: 700,
                      lineHeight: '28px', }}>
                          Login</span>
                  </Typography>}
          </Box>
        )}
        {this.state.isEmailVerified && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: '#f9f9f9',
              padding: 2,
              textAlign: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                mb: 4,
              }}
            >
              
                <Box><img src={this.state.successLogo}></img></Box>
              
              <Typography variant="h5" sx={{  
                color:"#000000",
                fontFamily: 'Inter',
                fontSize: '30px',
                fontWeight: 700,
                lineHeight: '40px',
                letterSpacing: '-0.005em',
                textAlign: 'left', 
                marginTop:'36px'}}>
                Email Verified
              </Typography>
              <Box sx={{
                display: 'flex',
                flexWrap: 'wrap',
                paddingRight: '83px',
                paddingLeft: '83px'
              
              }}>
              <Typography variant="body1" sx={{ 
                fontFamily: 'Inter',
                fontSize: '20px',
                fontWeight: 400,
                lineHeight: '28px',
                textAlign: 'center',
                color :'#1E293B',
                marginTop:'24px',
                [theme.breakpoints.down('xs')]:{
                  fontSize: '16px',

                }
              }}>
                Your account is successfully verified,<br/> you can now browse the app with your new account.
              </Typography>
              </Box>
            </Box>
            <Button
              onClick={this.navigateToLandingPage}
              variant="contained"
              sx={{
                backgroundColor: '#A855F7',
                color: '#fff',
                width:'80%',
                height: '56px',
                borderRadius: '8px',
                '&:hover': {
                  backgroundColor: '#9333EA',
                },

                fontFamily: 'Inter',
                fontSize: '20px',
                fontWeight: 700,
                lineHeight: '28px',
                textAlign: 'left',
                textTransform:'none'
              }}
            >
              Continue
            </Button>
          </Box>
        )}
        </ThemeProvider>
        </MainWrapper>
        </>
     
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainWrapper = styled(Box)(({ theme }) => ({

  "& .otp-input": {
      width: '5vw !important', 
                  padding: '10px',
                  borderRadius: '8px',
                  border: '1px solid #CBD5E1',
                  backgroundColor: '#FFFFFF',
                  textAlign: 'center',
                  boxSizing: 'border-box',
                  fontSize: '20px',
                  margin: '0', 
                  height: '56px',
                  marginRight: '40px',

                  [theme.breakpoints.down('lg')]: {
                    width: '15vw', 
                    fontSize: '18px',
                    marginRight: '40px',
                    maxWidth: '97px', 
                    height: '56px',
  

                  },
                 
                  [theme.breakpoints.down('md')]: {
                    width: '15vw', 
                    height: '45px',
                    fontSize: '18px',
                    marginRight: '6px',

                  },
                  [theme.breakpoints.down('sm')]: {
                    width: '13vw !important', 
                    height: '40px',
                    fontSize: '16px',
                    marginRight: '5px',
                    padding: 0
                  },
                  [theme.breakpoints.down('xs')]: {
                    width: '5vw', 
                    height: '35px',
                    fontSize: '14px',
                    marginRight: '4px',
                    padding: 0
                  },

  }
})); 

// Customizable Area End

export default OTPInputAuth;
