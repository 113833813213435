import React from 'react';
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const leftBannerImg = require("../assets/leftBannerImg.png");
export const logo = require("../assets/logo.svg");
export const valid = require("../assets/valid.svg");
export const invalid = require("../assets/invalid.svg");
export const GermanFlag = require("../assets/GermanFlag.png");
export const uploadIcon = require("../assets/uploadIcon.png");

export const CheckIcon = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="#34D399"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M17.7396 7.05521C18.2618 7.46322 18.3544 8.21735 17.9464 8.73961L11.6964 16.7396C11.4905 17.0031 11.1841 17.1688 10.8508 17.1967C10.5176 17.2245 10.1879 17.1121 9.94106 16.8865L6.19107 13.4579C5.70194 13.0107 5.66796 12.2517 6.11515 11.7625C6.56235 11.2734 7.32139 11.2394 7.81052 11.6866L10.6035 14.2402L16.0552 7.26206C16.4632 6.73981 17.2173 6.64719 17.7396 7.05521Z" fill="white"/>
    </svg>
  );
  
export const CrossIcon = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_20971_1127)">
        <rect width="24" height="24" rx="12" fill="#DC2626"/>
        <rect x="4" y="11" width="15" height="2.5" rx="1.25" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_20971_1127">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );